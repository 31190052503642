import { Typography } from "@mui/material";
import { ComponentProps, LabelHTMLAttributes } from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material";
import Image from "next/image";
import styles from "../preloader.module.css";

export function Preloader(props: any) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.isHydrated) {
      setTimeout(() => setLoaded(true), 1000);
    }
  }, [props]);

  return (
    // @ts-ignore
    <>
      {loaded ? (
        <></>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            zIndex: 1000,
          }}
          className={`${loaded && styles["preloader-fade-out"]}`}
        >
          <Image
            src={"/assets/logo.png"}
            alt={"Fire Notifications Logo"}
            width={100}
            height={100}
            className={styles["pulse"]}
          />
        </div>
      )}
    </>
  );
}
