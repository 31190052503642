import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
import { dayjs } from "@/util/dayjs";
import qs from "qs";

interface ApiResponse<T = any> {
  data?: T;
  message?: string;
  status?: number;
}

interface ApiErrorData {
  message?: string;
  status?: number;
  [key: string]: any;
}

type ExtendedAxiosError = AxiosError<ApiErrorData> & {
  originalMessage?: string;
  timestamp?: string;
  config?: InternalAxiosRequestConfig;
};

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
if (!BASE_URL) {
  throw new Error("NEXT_PUBLIC_BASE_URL environment variable is not defined");
}

const API_URL = `${BASE_URL}/api`;

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    common: {
      "x-platform": "web",
      "x-client": "Fire Notification",
      "x-app-version": "0.0.1",
      "x-timezone": dayjs.tz.guess(),
      Accept: "application/json",
      "Content-Type": "application/json",
      crossorigin: true,
    } as RawAxiosRequestHeaders,
  },
  paramsSerializer: {
    serialize: (params) =>
      qs.stringify(params, {
        arrayFormat: "repeat",
        skipNulls: true,
      }),
  },
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.set("Authorization", `Bearer ${token}`);
      }

      const domain = new URL(BASE_URL).hostname;
      config.headers.set("Cookie-Domain", domain);

      if (process.env.NODE_ENV === "development") {
      }

      return config;
    } catch (error) {
      console.error("Request interceptor error:", error);
      return Promise.reject(error);
    }
  },
  (error: unknown) => {
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    try {
      if (process.env.NODE_ENV === "development") {
      }

      if (response.status === 304) {
        return response;
      }

      const token = response.headers.authorization;
      if (token) {
        localStorage.setItem("accessToken", token);
        api.defaults.headers.common["Authorization"] = token;
      }

      return response;
    } catch (error) {
      console.error("Response processing error:", error);
      return Promise.reject(error);
    }
  },
  (error: unknown): Promise<never> => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (!axios.isAxiosError(error)) {
      console.error("Non-Axios error:", error);
      return Promise.reject(new Error("An unknown error occurred"));
    }

    const axiosError = error as ExtendedAxiosError;

    if (process.env.NODE_ENV === "development") {
      console.error("API Error:", {
        name: axiosError.name,
        message: axiosError.message,
        status: axiosError.response?.status,
        statusText: axiosError.response?.statusText,
        data: axiosError.response?.data,
        headers: axiosError.response?.headers,
        config: {
          url: axiosError.config?.url,
          method: axiosError.config?.method,
          headers: axiosError.config?.headers,
          baseURL: axiosError.config?.baseURL,
          params: axiosError.config?.params,
        },
      });
    }

    if (axiosError.response?.status === 401) {
      localStorage.removeItem("accessToken");
      if (typeof window !== "undefined") {
        window.location.href = "/auth/sign-in";
      }
      return Promise.reject(axiosError);
    }

    const errorResponse: ApiErrorData = {
      message:
        axiosError.response?.data?.message ||
        axiosError.response?.statusText ||
        "We encountered a problem trying to process your request. Please try again.",
      status: axiosError.response?.status || 500,
    };

    return Promise.reject({
      ...axiosError,
      originalMessage: axiosError.message,
      response: {
        ...axiosError.response,
        data: errorResponse,
      },
      timestamp: new Date().toISOString(),
    } as ExtendedAxiosError);
  },
);

axios.defaults.timeout = 30000;
axios.defaults.timeoutErrorMessage = "Request timeout - please try again";

export type ApiError = ExtendedAxiosError;

export const checkApiHealth = async (): Promise<boolean> => {
  try {
    const response = await api.get("/health");
    return response.status === 200;
  } catch (error) {
    console.error("API health check failed:", error);
    return false;
  }
};

export const handleApiError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as ExtendedAxiosError;
    return (
      axiosError.response?.data?.message ||
      axiosError.message ||
      "An unexpected error occurred"
    );
  }
  return "An unexpected error occurred";
};
