import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import duration from "dayjs/plugin/duration";

dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);

let detectedTimezone = "UTC";
try {
  const guessedTz = dayjs.tz.guess();
  if (guessedTz) {
    detectedTimezone = guessedTz;
  }
  console.log("[dayjs] Detected timezone:", detectedTimezone);
} catch (error) {
  console.warn("[dayjs] Error detecting timezone, defaulting to UTC:", error);
}

export const localTimezone = detectedTimezone;

export const subtractDays = (days: number) => () =>
  dayjs().subtract(days, "days").startOf("day").toDate();

export const fromMap = {
  today: () => dayjs().startOf("day").toDate(),
  yesterday: subtractDays(1),
  last7: subtractDays(7),
  last30: subtractDays(30),
};

export { dayjs };
