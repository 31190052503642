"use client";
import { api } from "@/api";
import { User } from "@/types";
import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import axios from "axios";

type Request = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type Response = {
  user: User;
};

export function useSignIn(options?: UseMutationOptions<User, Error, Request>) {
  const queryClient = useQueryClient();
  const router = useRouter();

  return useMutation({
    mutationFn: async ({ email, password, rememberMe }) => {
      try {
        const {
          data: { user: self },
        } = await api.post<Response>("auth/signIn", {
          email,
          password,
          rememberMe,
        });
        return self;
      } catch (error) {
        // Handle different error response formats
        if (axios.isAxiosError(error)) {
          console.log("Error response:", error.response);

          if (error.request?.responseText) {
            try {
              const parsedResponse = JSON.parse(error.request.responseText);
              if (parsedResponse.message) {
                throw new Error(parsedResponse.message);
              }
            } catch (parseError) {
              console.log("Failed to parse responseText:", parseError);
            }
          }

          if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
          } else if (error.response?.data?.success === false) {
            throw new Error(
              error.response.data.message || "Authentication failed",
            );
          } else if (typeof error.response?.data === "string") {
            throw new Error(error.response.data);
          } else if (error.response?.statusText) {
            throw new Error(
              `${error.response.status}: ${error.response.statusText}`,
            );
          }
        }
        throw error;
      }
    },
    ...options,
  });
}
