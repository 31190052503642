"use client";

import { PasswordField } from "@/components/PasswordField";
import { Preloader } from "@/components/preloader";
import { useSignIn } from "@/hooks/useSignIn";
import EmailIcon from "@mui/icons-material/Email";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  Container,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  email: z
    .string()
    .min(1, "You must provide your email")
    .email("Invalid email"),
  password: z.string().min(1, "You must provide your password"),
});

type Props = {
  searchParams?: {
    destination?: string;
    email?: string;
  };
};

export default function SignInPage({ searchParams }: Props) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const signIn = useSignIn();
  const [errorMessage, setErrorMessage] = useState("");

  const form = useForm({
    defaultValues: {
      email: searchParams?.email || "",
      password: "",
      rememberMe: false,
    },
  });

  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      const subscription = form.watch(() => setErrorMessage(""));
      return () => subscription.unsubscribe();
    }
  }, [form, errorMessage]);

  return (
    <Stack
      sx={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Preloader isHydrated={isHydrated} />
      <Stack
        component="form"
        onSubmit={form.handleSubmit((data) => {
          setErrorMessage("");
          signIn.mutate(data, {
            onSuccess: (self) => {
              queryClient.setQueryData(["self"], self);
              router.replace(searchParams?.destination ?? "/");
            },

            onError: (error) => {
              //@ts-ignore
              setErrorMessage(
                error.response.data.message ||
                  "Failed to sign in. Please try again.",
              );
            },
          });
        })}
        sx={{
          width: "100%",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            textAlign: "center",
            minHeight: "500px",
            padding: "1rem",
            display: "flex",
            flex: 1,
          }}
        >
          <Stack
            sx={{
              padding: "1rem 2rem",
              flex: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "1.8rem",
              }}
            >
              Sign In
            </Typography>

            <Stack
              sx={{
                flex: 1,
                justifyContent: "center",
                padding: "0 3rem",
              }}
            >
              <Stack
                sx={{
                  gap: "2rem",
                }}
              >
                <TextField
                  required
                  label="Email Address"
                  type="email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),

                    ...form.register("email"),
                  }}
                />

                <PasswordField
                  required
                  label="Password"
                  InputProps={form.register("password")}
                />
              </Stack>

              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="rememberMe"
                      defaultChecked={form.formState.defaultValues?.rememberMe}
                      {...form.register("rememberMe")}
                    />
                  }
                  label="Remember Me"
                  htmlFor="rememberMe"
                />

                <Typography
                  component={Link}
                  prefetch={false}
                  href="/auth/forgot-password"
                  tabIndex={-1}
                  sx={{
                    color: "#4487EE",
                    fontWeight: "600",
                  }}
                >
                  Forgot Password
                </Typography>
              </Stack>

              <LoadingButton
                type="submit"
                loading={signIn.isPending || signIn.isSuccess}
                sx={{
                  marginTop: "2rem",
                }}
              >
                {signIn.isPending || signIn.isSuccess ? null : "Sign In"}
              </LoadingButton>

              {errorMessage && (
                <Alert
                  severity="error"
                  sx={{
                    mt: 2,
                    textAlign: "left",
                  }}
                >
                  {errorMessage}
                </Alert>
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
